<template>
  <div class="social_share_buutons">
    <div v-if="showRedbubble" class="redbubble-logo button-social">
      <a href="https://toasterartist.redbubble.com" target="_blank">
        <img :src="redbubbleLogo" />
      </a>
    </div>

    <vue-goodshare-facebook
      :page_url="pageUrl"
      title_social=""
      has_icon
    />

    <vue-goodshare-pinterest
      :page_url="pageUrl"
      title_social=""
      has_icon
    />

    <vue-goodshare-twitter
      :page_url="pageUrl"
      title_social=""
      has_icon
    />

    <vue-goodshare-reddit
      :page_url="pageUrl"
      title_social=""
      has_icon
    />

    <vue-goodshare-linked-in
      :page_url="pageUrl"
      title_social=""
      has_icon
    />
  </div>
</template>

<script>
  import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
  import VueGoodsharePinterest from "vue-goodshare/src/providers/Pinterest.vue";
  import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
  import VueGoodshareLinkedIn from "vue-goodshare/src/providers/LinkedIn.vue";
  import VueGoodshareReddit from "vue-goodshare/src/providers/Reddit.vue";

  export default {
    components: {
      VueGoodshareFacebook,
      VueGoodsharePinterest,
      VueGoodshareLinkedIn,
      VueGoodshareTwitter,
      VueGoodshareReddit,
    },
    data() {
      return {
        redbubbleLogo: require('../assets/img/marketplace/redbubble.png'),
      };
    },
    props: {
      showRedbubble: {
        Boolean,
        default: false,
      },
      pageUrl: {
        String,
        required: true
      },
    },
    computed: {
    },
    methods: {
    },
  };
</script>

<style lang="scss" scoped>
  .social_share_buutons {
    padding-bottom: 12px;
    text-align: right;
  }

  .button-social {
    padding: 1px 3px;
  }

  .redbubble-logo {
    display: inline-block;
    width: 120px;
    padding-right: 15px;
    margin: auto auto auto 0;
  }
</style>
